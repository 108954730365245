<template>
    <div class="login-msg-wrapper" id="mes-wrap">
        <span class="skip" @click="skip">SKIP</span>
        <div data-text></div>
    </div>
</template>

<script>
import { getYearMessage, ReadDone } from '@/api/api'

export default {
    name: 'weclome',
    data() {
        return {
            wrapper: null,
            sleep: (ms) => new Promise(resolve => setTimeout(resolve, ms)),
            message: null
        }
    },
    created() {
        this.getYearMessage()
    },
    methods: {
        getYearMessage() {
            getYearMessage().then(data => {
                this.message = data.data.response.context;
            })
                .catch(() => {
                    this.$notify.error({
                        title: '错误',
                        message: '网络开小差喽',
                        duration: 3000
                    })
                })
        },
        skip() {
            this.$router.replace('birthday')
        },
        createTem() {
            let mesclip = this.message.split('。');
            if (mesclip && mesclip.length > 0) {
                let warpper = document.getElementById('mes-wrap');
                for (let i = 0; i < mesclip.length; ++i) {
                    let span = document.createElement("span");
                    span.innerText = mesclip[i];
                    span.className = 'item';
                    warpper.appendChild(span)
                }
                this.writingAll('item', 'data-text');
            }
        },
        async writingAll(stringTarget, container) {
            this.wrapper = document.querySelector('[' + container + ']')
            const stringsContainer = document.getElementsByClassName(stringTarget)
            console.log(stringsContainer.length)
            let times = 0;
            while (this.wrapper && times < stringsContainer.length) {
                const string = stringsContainer[times].textContent
                await this.write(string)
                await this.sleep(1000)
                if (times !== stringsContainer.length - 1) {
                    await this.sleep(500)
                    await this.erase()
                }
                await this.sleep(1000);
                times++;
            }

            if (this.$route.path === '/welcome') {
                ReadDone().then(res => {
                    setTimeout(() => {
                        this.$router.replace('birthday')
                    }, 2000)
                })
            }


        },
        async write(text) {
            let index = 0
            while (index < text.length) {
                const timeout = 100
                await this.sleep(timeout)
                index++
                this.wrapper.innerHTML = text.substring(0, index)
            }
        },
        async erase() {
            while (this.wrapper.textContent.length) {
                const timeout = 100
                await this.sleep(timeout)
                this.wrapper.textContent = this.wrapper.textContent.substring(0, this.wrapper.textContent.length - 2)
            }
        }
    },
    watch: {
        message: {
            handler() {
                setTimeout(() => {
                    this.createTem()
                },2000)
            }
        }
    },
}
</script>

<style lang="scss" scope>
.login-msg-wrapper {
    // padding: 10px;
    margin: 0 auto;
    padding: 0 30px;
    height: 100vh;
    text-align: center;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    // transform: translate(-50%, -50%);

    .skip {
        position: fixed;
        right: 1rem;
        top: 1rem;
        color: #e98787;
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
    }

    .item {
        visibility: hidden;
        display: none;
    }

    h2 {
        font-size: 50px;
        margin: 0;
        color: #3a3a3a;
        text-align: left;
    }

    >div {
        min-height: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-weight: bold;
        color: #e98787;

        &:after {
            content: "";
            width: 10px;
            height: 4px;
            display: block;
            background: black;
            color: #e98787;
            margin-bottom: 4px;
            margin-left: 2px;
            animation-duration: 350ms;
            animation-name: fade;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
</style>